import React from "react";
import ModelCardSimple from "./ModalCardSimple";
import GenericCard from "./GenericCard";
import { urlFor } from "../utils/urlFor";

const HomePageCategorySection = ({ data }) => {
  const { name, models } = data;

  const prioritizedModels = models.filter((model) => model.isPrioritizedModel);

  return (
    <section className="bg-gray-50 py-14">
      <div className="max-w-screen-xl p-4 m-auto">
        <div className="flex items-center justify-between">
          <h2 className="text-5xl font-bold mb-14">{name}</h2>
        </div>
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3">
          {prioritizedModels?.map((model) => (
            <ModelCardSimple
              key={model.title}
              title={model.title}
              introduction={model.introText}
              imageSrc={
                model?.prioritizedImage?.asset
                  ? urlFor(model.prioritizedImage).width(1000).height(600).url()
                  : ""
              }
            />
          ))}
          <GenericCard
            title={`${name}-modellene`}
            text={`Utforsk ${name}-modellene`}
            link={`/${name.toLowerCase()}`}
          />
        </div>
      </div>
    </section>
  );
};

export default HomePageCategorySection;
