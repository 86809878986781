import { Banner } from "./Banner";
import { Link, NavLink } from "react-router-dom";
import { useUIContext } from "./context/UiContext";
import Contact from "./components/Contact";
import React from "react";
import Drawer from "./components/Drawer";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

export const Header = () => {
  const { openModal } = useUIContext();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <header className="flex-none w-full">
      <Banner />
      <nav className="top-0 left-0 z-20 w-full bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-600">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl p-4 mx-auto">
          <Link to="/" className="flex items-center">
            <div className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white w-[130px] md:w-[160px]">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 888 153">
                <g id="Layer_1" data-name="Layer 1">
                  <path
                    class="cls-1"
                    d="M33.49 55.83v-21h117v-9h-123a4 4 0 0 0-4 4v3.72h-.05V60.83a4 4 0 0 0 4 4h110a4 4 0 0 1 4 4v44.08a4 4 0 0 1-4 4h-113v9h123a4 4 0 0 0 4-4V59.83a4 4 0 0 0-4-4zM192.6 112.91V64.83h28a4 4 0 0 0 4-4v-1a4 4 0 0 0-4-4h-28v-30h-10v96.06a4 4 0 0 0 4 4h40v-9h-30a4 4 0 0 1-4-3.98zM419.67 60.83v9h-.05v56.08h10V73.83a4 4 0 0 1 4-4h26.05a4 4 0 0 0 4-4v-1a4 4 0 0 0-4-4zM500.51 60.83a4 4 0 0 0-4 4v61.08h10V73.83a4 4 0 0 1 4-4h41a4 4 0 0 1 4 4v52.08h10V73.83a4 4 0 0 1 4-4h41a4 4 0 0 1 4 4v52.08h10V64.83a4 4 0 0 0-4-4zM780.64 60.83h-120a4 4 0 0 0-4 4v57.08a4 4 0 0 0 4 4h117.5a6.5 6.5 0 0 0 6.5-6.5v-2.5h-114a4 4 0 0 1-4-4v-24a4 4 0 0 1 4-4h110a4 4 0 0 0 4-4v-16a4 4 0 0 0-4-4.08zm-6.11 15H666.64v-6h107.89zM265.08 60.83a4 4 0 0 0-4 4v57.08a4 4 0 0 0 4 4h120a4 4 0 0 0 4-4V64.83a4 4 0 0 0-4-4zm110 56.08h-100a4 4 0 0 1-4-4V73.83a4 4 0 0 1 4-4h100a4 4 0 0 1 4 4v39.08a4 4 0 0 1-4 4zM816.54 60.83v65.08h10V73.83a4 4 0 0 1 4-4h26a4 4 0 0 0 4-4v-1a4 4 0 0 0-4-4z"
                  ></path>
                </g>
              </svg>
            </div>
          </Link>
          <div className="flex items-center gap-6 md:order-2">
            <div className="hidden md:block">
              <NavLink
                to="/selskapet"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                    : isActive
                    ? "text-blue-700"
                    : ""
                }
              >
                Om selskapet
              </NavLink>
            </div>
            <button
              onClick={() => openModal(<Contact />)}
              type="button"
              className="hidden px-4 py-2 mr-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg sm:inline-block hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 md:mr-0 "
            >
              Kontakt oss
            </button>
            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-sticky"
              aria-expanded="false"
              onClick={() => setIsOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg md:p-0 bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink
                  to="/vesling"
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                      : isActive
                      ? "text-blue-700"
                      : ""
                  }
                >
                  Vesling
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/musling"
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                      : isActive
                      ? "text-blue-700"
                      : ""
                  }
                >
                  Musling
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sportling"
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                      : isActive
                      ? "text-blue-700"
                      : ""
                  }
                >
                  Sportling
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
          <div className="p-8">
            <div className="mb-2">
              <NavLink
                to="/vesling"
                onClick={() => setIsOpen(false)}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                    : isActive
                    ? "text-blue-700"
                    : ""
                }
              >
                Vesling
              </NavLink>
            </div>
            <div className="mb-2">
              <NavLink
                to="/musling"
                onClick={() => setIsOpen(false)}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                    : isActive
                    ? "text-blue-700"
                    : ""
                }
              >
                Musling
              </NavLink>
            </div>
            <div className="mb-2">
              <NavLink
                to="/sportling"
                onClick={() => setIsOpen(false)}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                    : isActive
                    ? "text-blue-700"
                    : ""
                }
              >
                Sportling
              </NavLink>
            </div>
            <div className="mb-2">
              <NavLink
                to="/selskapet"
                onClick={() => setIsOpen(false)}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 "
                    : isActive
                    ? "text-blue-700"
                    : ""
                }
              >
                Om selskapet
              </NavLink>
            </div>
            <div className="mt-8">
              <div className="flex items-center mb-3">
                <PhoneIcon className="w-6 h-6 mr-2 text-blue-600" />
                <a
                  href="tel:+4797033832"
                  className="text-gray-700 hover:text-blue-600"
                >
                  +47 970 33 832
                </a>
              </div>

              <div className="flex items-center">
                <EnvelopeIcon className="w-6 h-6 mr-2 text-blue-600" />
                <a
                  href="mailto:post@stormermarine.no"
                  className="text-gray-700 hover:text-blue-600"
                >
                  post@stormermarine.no
                </a>
              </div>
            </div>
          </div>
        </Drawer>
      </nav>
    </header>
  );
};
