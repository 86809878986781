import { PhotoIcon } from "@heroicons/react/24/outline";
import { PortableText } from "@portabletext/react";
import React from "react";
import { useUIContext } from "../context/UiContext";
import { urlFor } from "../utils/urlFor";
import { MyGalleryCarousel } from "./MyGalleryCarousel";
import { Flowbite } from "flowbite-react";
import { Carousel } from "flowbite-react";

const components = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code className="font-semibold">{props.node.code}</code>
      </pre>
    ),
  },
  block: {
    h1: ({ children }) => (
      <h1 className="mt-8 mb-4 text-2xl font-semibold md:text-5xl">
        {children}
      </h1>
    ),
    h2: ({ children }) => (
      <h2 className="mt-8 mb-4 text-2xl font-semibold md:text-4xl">
        {children}
      </h2>
    ),
    h3: ({ children }) => (
      <h3 className="mt-8 mb-4 text-2xl font-semibold md:text-3xl">
        {children}
      </h3>
    ),
    h4: ({ children }) => (
      <h4 className="mt-8 mb-4 text-2xl font-semibold md:text-3xl">
        {children}
      </h4>
    ),
  },
  listItem: {
    // Ex. 1: customizing common list types
    bullet: ({ children }) => (
      <li
        style={{ listStyleType: "disc", marginLeft: "30px" }}
        className="font-semibold"
      >
        {children}
      </li>
    ),
  },
};

const BoatGallery = ({ images }) => {
  return (
    <div className="h-64 md:h-96 2xl:h-96">
      <Carousel>
        {images?.map((image) => (
          <img
            alt="Stormer Marine"
            src={urlFor(image).width(1920).height(1080).url()}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default function BoatTemplate({ boat }) {
  const { openModal } = useUIContext();
  if (!boat) return <div>Ingen båt funnet...</div>;

  const {
    title,
    length,
    width,
    numberOfPersons,
    numberOfBeds,
    motor,
    speed,
    weight,
    vannballast,
    ceCategory,
    content,
    prioritizedImage,
    standardEquipment,
    extraEquipment,
    images,
  } = boat;

  return (
    <div className="">
      <div>
        <div
          className="mb-8 h-[50vh] md:h-[75vh]"
          style={{
            backgroundImage: `url(${urlFor(prioritizedImage)
              .width(1920)
              .height(1080)
              .url()})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
          }}
        >
          <div className="flex h-full max-w-screen-xl p-4 m-auto">
            <div className="flex flex-col items-start justify-end w-full pb-8 md:flex-row md:justify-between md:items-end">
              <h3 className="text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl">
                {title ?? "Ingen båttittel"}
              </h3>
              {images && (
                <button
                  onClick={() => openModal(<BoatGallery images={images} />)}
                  className="inline-flex items-center justify-center px-5 py-3 mt-4 text-base font-medium text-center text-white bg-blue-700 rounded-lg shadow-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
                >
                  Bildegalleri
                  <PhotoIcon className="w-6 h-6 ml-4" />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="max-w-screen-xl p-4 m-auto">
          <div className="flex flex-col justify-between w-full gap-8 lg:flex-row">
            <div>
              <div className="mb-8">
                <h4 className="mb-4 text-lg md:text-2xl">Standardutstyr:</h4>
                <div className="flex flex-wrap gap-2">
                  {standardEquipment?.map((item) => (
                    <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                      {item}
                    </span>
                  )) ?? "Ikke lagt til"}
                </div>
              </div>
              <div className="">
                <h4 className="mb-4 text-lg md:text-2xl">Ekstrautstyr:</h4>
                <div className="flex flex-wrap gap-2">
                  {extraEquipment?.map((item) => (
                    <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                      {item}
                    </span>
                  )) ?? "Ikke lagt til"}
                </div>
              </div>
            </div>
            <div className="p-4 text-sm rounded-lg lg:p-8 bg-gray-50">
              <h4 className="block mb-4 text-lg md:text-2xl">
                Spesifikasjoner:
              </h4>
              <ul>
                {length && (
                  <li>
                    <p>
                      Lengde: <span className="font-semibold">{length}</span>
                    </p>
                  </li>
                )}
                {width && (
                  <li>
                    <p>
                      Bredde: <span className="font-semibold">{width}</span>
                    </p>
                  </li>
                )}
                {numberOfPersons && (
                  <li>
                    <p>
                      Antall personer:{" "}
                      <span className="font-semibold">{numberOfPersons}</span>
                    </p>
                  </li>
                )}
                {numberOfBeds && (
                  <li>
                    <p>
                      Antall sengeplasser:{" "}
                      <span className="font-semibold">{numberOfBeds}</span>
                    </p>
                  </li>
                )}
                {motor && (
                  <li>
                    <p>
                      Motor: <span className="font-semibold">{motor}</span>
                    </p>
                  </li>
                )}
                {speed && (
                  <li>
                    <p>
                      Fart: <span className="font-semibold">{speed}</span>
                    </p>
                  </li>
                )}
                {weight && (
                  <li>
                    <p>
                      Vekt: <span className="font-semibold">{weight}</span>
                    </p>
                  </li>
                )}
                {vannballast && (
                  <li>
                    <p>
                      Vannballast:{" "}
                      <span className="font-semibold">{vannballast}</span>
                    </p>
                  </li>
                )}
                {ceCategory && (
                  <li>
                    <p>
                      CE Kategori:{" "}
                      <span className="font-semibold">{ceCategory}</span>
                    </p>
                  </li>
                )}
              </ul>
            </div>
          </div>

          {content && (
            <div className="w-full md:max-w-4xl ">
              <PortableText value={content} components={components} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
