// NotFound.jsx
import React from "react";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-gray-900 bg-gray-100">
      <div className="w-3/4 p-8 bg-white rounded-lg shadow-md md:w-1/2 lg:w-1/3">
        <div className="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-32 h-32 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-.894.553L3.382 8H1a1 1 0 100 2h2.535l.293 1.068L1 14h10l-.373-1.361L11.535 12H20a1 1 0 100-2h-2.535l-.293-1.068L19 8H9l.373 1.361L8.465 10H1.618l1.724-6.553A1 1 0 006 2zM4.618 4L3.382 8H9L7.784 4H4.618zM11 12l-2.784 1H17l-1.236-1H11zm.618-8L13.382 8H15l-2.784 1h3.167l1.724 6.553A1 1 0 0117 16H3a1 1 0 01-.894-1.447L4.618 12H1.236L2.472 11H9l2.784-1H8.597z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h1 className="mb-4 text-2xl font-semibold">Ikke funnet</h1>
        <p className="mb-4 text-gray-700">
          Beklager, siden du leter etter finnes ikke.
        </p>
        <a href="/" className="text-blue-500 hover:underline">
          Tilbake til forsiden
        </a>
      </div>
    </div>
  );
};

export default NotFound;
