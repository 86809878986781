import sanity from "./sanityClient";

export async function getBoats() {
  const boats = await sanity.fetch('*[_type == "boatModels"]');
  return boats;
}

export async function getFrontpageImages() {
  const data = await sanity.fetch('*[_type == "hero"]');
  return data;
}

export async function getNews() {
  const data = await sanity.fetch('*[_type == "news"]');
  return data;
}

const query = `
  *[_type == "boatCategory"] {
    name,
    about,
    "models": models[]->{
      title,
      introText,
      isPrioritizedModel,
      prioritizedImage
    }
  }
`;

export async function getCategories() {
  const categories = await sanity.fetch(query);
  return categories;
}

export async function getCompany() {
  const company = await sanity.fetch('*[_type == "about"]');
  return company;
}

const veslingQuery = `
  *[_type == "boatCategory" && name == "Vesling"] {
    name,
    about,
    "models": models[]->{
      title,
      slug,
      introText,
      isPrioritizedModel,
      prioritizedImage,
      content,
      standardEquipment,
      extraEquipment,
      length,
      width,
      numberOfPersons,
      numberOfBeds,
      motor,
      speed,
      vannballast,
      ceCategory,
      images
    }
  }
`;

export async function getVeslingCategory() {
  const category = await sanity.fetch(veslingQuery);
  return category;
}

const muslingQuery = `
  *[_type == "boatCategory" && name == "Musling"] {
    name,
    about,
    "models": models[]->{
      title,
      slug,
      introText,
      isPrioritizedModel,
      prioritizedImage,
      content,
      standardEquipment,
      extraEquipment,
      length,
      width,
      numberOfPersons,
      numberOfBeds,
      motor,
      speed,
      vannballast,
      ceCategory,
      images
    }
  }
`;

export async function getMuslingCategory() {
  const category = await sanity.fetch(muslingQuery);
  return category;
}

const sportlingQuery = `
  *[_type == "boatCategory" && name == "Sportling"] {
    name,
    about,
    "models": models[]->{
      title,
      slug,
      introText,
      isPrioritizedModel,
      prioritizedImage,
      content,
      standardEquipment,
      extraEquipment,
      length,
      width,
      numberOfPersons,
      numberOfBeds,
      motor,
      speed,
      vannballast,
      ceCategory,
      images
    }
  }
`;

export async function getSportlingCategory() {
  const category = await sanity.fetch(sportlingQuery);
  return category;
}
