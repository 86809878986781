// Layout.js
import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Modal } from "./components/Modal";
import { UIProvider } from "./context/UiContext";

const Layout = ({ children }) => {
  return (
    <div>
      <UIProvider>
        <Header />
        <main className="min-h-screen">{children}</main>
        <Footer />
        <Modal />
      </UIProvider>
    </div>
  );
};

export default Layout;
