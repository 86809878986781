import React from "react";
import { Link } from "react-router-dom";

const ModelCard = ({ title, introduction, imageSrc, link }) => {
  return (
    <div className="overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
      <Link to={link}>
        <img alt="Stormer Marine" src={imageSrc} />
      </Link>
      <div className="p-5">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {introduction}
        </p>
        <Link
          to={link}
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 mt-4 inline-flex"
        >
          Se båt
        </Link>
      </div>
    </div>
  );
};

export default ModelCard;
