import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";
import { getCompany, getCategories } from "./lib/fetch";
import { PortableText } from "@portabletext/react";

import { urlFor } from "./utils/urlFor";

const myPortableTextComponents = {
  types: {
    image: ({ value }) => <img src={value.imageUrl} />,
    callToAction: ({ value, isInline }) =>
      isInline ? (
        <a href={value.url}>{value.text}</a>
      ) : (
        <div className="callToAction">{value.text}</div>
      ),
  },

  marks: {
    link: ({ children, value }) => {
      const rel = !value.href.startsWith("/")
        ? "noreferrer noopener"
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const components = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const AboutPage = () => {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    getCompany()
      .then((fetchedData) => {
        setAboutData(fetchedData[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  if (!aboutData) return null;

  return (
    <div>
      <section className="p-6 pb-0 bg-white bg-center bg-no-repeat md:p-14 md:pb-0 dark:bg-gray-900">
        <div className="max-w-screen-xl mx-auto text-left md:text-center">
          <h1 className="mb-8 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            {aboutData.titleBlock1}
          </h1>
          <p className="text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
            <PortableText
              value={aboutData.contentBlock1}
              components={components}
            />
          </p>
          <div className="flex flex-wrap items-center justify-center gap-2 mt-8">
            {aboutData.punchlines.map((p) => (
              <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                {p}
              </span>
            ))}
          </div>
        </div>
      </section>

      <section className="p-6 md:p-20">
        <div className="max-w-5xl p-6 m-auto md:p-20 bg-gray-50 rounded-3xl">
          <h2 className="mb-8 text-2xl leading-none tracking-tight text-gray-900 dark:text-white">
            {aboutData.titleBlock2}
          </h2>
          <PortableText
            value={aboutData.contentBlock2}
            components={components}
          />
        </div>
      </section>

      <section className="">
        <div
          style={{
            backgroundImage: `url(${urlFor(aboutData.heroImage)
              .width(1920)
              .height(1080)
              .url()})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "75vh", // Setting the height to 75% of the viewport
            width: "100%",
          }}
          aria-label={aboutData.heroImage?.caption} // Optional, to provide a description for accessibility
        >
          {/* Other content inside this div if needed */}
        </div>
      </section>

      <section className="p-6 pb-0 md:p-20 md:pb-0">
        <div className="m-auto max-w-7xl">
          <p className="text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48">
            <PortableText
              value={aboutData.contentBlock3}
              components={components}
            />
          </p>
        </div>
      </section>

      <section className="p-6 md:p-20 md:pb-0">
        <div className="max-w-5xl p-6 m-auto md:p-20 bg-gray-50 rounded-3xl">
          <h2 className="mb-8 text-2xl leading-none tracking-tight text-gray-900 dark:text-white">
            {aboutData.titleBlock4}
          </h2>
          <PortableText
            value={aboutData.contentBlock4}
            components={components}
          />
        </div>
      </section>

      <section className="p-6 md:p-20">
        <div className="m-auto max-w-7xl">
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
            <PortableText
              value={aboutData.contentBlock5}
              components={components}
            />
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
