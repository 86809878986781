import React from "react";
import { Flowbite } from "flowbite-react";
import { Carousel } from "flowbite-react";
import { urlFor } from "../utils/urlFor";

const customTheme = {
  carousel: {
    scrollContainer: {
      base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none snap-x indiana-scroll-container indiana-scroll-container--hide-scrollbars",
    },
  },
};

export const MyCarousel = ({ images }) => {
  return (
    <Flowbite theme={{ theme: customTheme }}>
      <Carousel
        slideInterval={5000}
        indicators={false}
        leftControl={<div></div>}
        rightControl={<div></div>}
      >
        {images.map((image) => (
          <img
            alt="Stormer Marine"
            src={urlFor(image).width(1920).height(1080).url()}
          />
        ))}
      </Carousel>
    </Flowbite>
  );
};
