import React from "react";

const ModelCardSimple = ({ title, introduction, imageSrc, link }) => {
  return (
    <div className="overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
      <img alt="Stormer Marine" src={imageSrc} />
      <div className="p-5">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {introduction}
        </p>
      </div>
    </div>
  );
};

export default ModelCardSimple;
