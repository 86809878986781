import React, { useEffect, useState } from "react";
import { Route, Routes, Link, useParams, Outlet } from "react-router-dom";
import { getMuslingCategory } from "./lib/fetch";
import { BoatList } from "./components/BoatList";
import { PortableText } from "@portabletext/react";
import { filterByModel } from "./utils/filterByModel";
import BoatTemplate from "./components/BoatTemplate";

const MuslingPage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    getMuslingCategory()
      .then((fetchedData) => {
        setData(fetchedData[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  if (!data) return null;

  return (
    <div>
      <h1 className="sr-only">Stormer Marine Musling</h1>
      <Routes>
        <Route path="/" element={<MuslingList data={data} />} />
        <Route path=":slug" element={<MuslingBoat data={data} />} />
      </Routes>
    </div>
  );
};

const components = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
};

const MuslingList = ({ data }) => {
  return (
    <div>
      <section className="bg-white dark:bg-gray-900 ">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-left md:text-center lg:py-16">
          <div className="flex flex-col items-center justify-center w-full gap-2">
            <div className="w-20">
              <img
                src={process.env.PUBLIC_URL + "/linge.jpg"}
                alt="Linge Design"
              />
            </div>
            <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
              Musling
            </h1>
          </div>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl lg:px-48 dark:text-gray-400">
            <PortableText value={data.about} components={components} />
          </p>
        </div>
      </section>
      {data && <BoatList data={data} />}
    </div>
  );
};

const MuslingBoat = ({ data }) => {
  let { slug } = useParams();

  const boat = filterByModel(data, slug);

  if (!boat) return <p>Ingen båt funnet</p>;

  return <BoatTemplate boat={boat} />;
};

export default MuslingPage;
