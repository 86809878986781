// Footer.js
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <footer className="m-4 bg-white rounded-lg shadow dark:bg-gray-800">
      <div className="w-full max-w-screen-xl p-4 mx-auto md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023{" "}
          <Link to="/" className="hover:underline">
            Stormer Marine™
          </Link>
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <Link to="/vesling" className="mr-4 hover:underline md:mr-6 ">
              Vesling
            </Link>
          </li>
          <li>
            <Link to="/musling" className="mr-4 hover:underline md:mr-6">
              Musling
            </Link>
          </li>
          <li>
            <Link to="/sportling" className="mr-4 hover:underline md:mr-6">
              Sportling
            </Link>
          </li>
          <li>
            <Link to="/selskapet" className="mr-4 hover:underline md:mr-6">
              Om selskapet
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};
