// import logo from './logo.svg';

import React, { useState, useEffect } from "react";
import { getBoats, getCategories, getFrontpageImages } from "./lib/fetch";
import { urlFor } from "./utils/urlFor";
import { Link } from "react-router-dom";
import HomePageCategorySection from "./components/HomePageCategory";
import { MyCarousel } from "./components/Carousel";

const filterByCategory = (data, category, maxItems) => {
  return data.filter((item) => {
    return item.name.toLowerCase().includes(category.toLowerCase());
  })[0];
};

const LandingPage = () => {
  const [categories, setCategories] = useState([]);
  const [hero, setHero] = useState(null);

  useEffect(() => {
    Promise.all([getCategories(), getFrontpageImages()])
      .then(([fetchedCategories, fetchedImages]) => {
        setHero(fetchedImages[0]);
        setCategories(fetchedCategories);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const sportling = filterByCategory(categories, "sportling");
  const vesling = filterByCategory(categories, "vesling");
  const musling = filterByCategory(categories, "musling");

  return (
    <div>
      <h1 className="sr-only">
        Stormer Marine og de legendariske Linge båtene
      </h1>
      <div className="w-full m-auto h-[20vh] sm:h-[30vh] md:h-[40vh] lg:h-[50vh] xl:h-[60vh] 2xl:h-[70vh]">
        {hero && hero.images && <MyCarousel images={hero.images} />}
      </div>
      <section className="bg-white dark:bg-gray-900 ">
        <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16">
          <div className="w-32 m-auto mb-6 md:w-46">
            <img
              src={process.env.PUBLIC_URL + "/linge.jpg"}
              alt="Linge Design"
            />
          </div>
          <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            De legendariske båtene
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">
            De legendariske vannballast-tunnellbåtene signert Jan Herman Linge
            er tilbake, og produseres nå av{" "}
            <span className="font-bold">Stormer Marine</span>. Båtene er blant
            de beste i klassen når det gjelder sjø - og fartsegenskaper, og har
            et tidløst og klassisk design.
          </p>
          {/* <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <Link
              to="#"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
            >
              Se modellene
            </Link>
            <Link
              to="/selskapet"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              Les om selskapet
            </Link>
          </div> */}
        </div>
      </section>
      {sportling && <HomePageCategorySection data={sportling} />}
      {vesling && <HomePageCategorySection data={vesling} />}
      {musling && <HomePageCategorySection data={musling} />}
    </div>
  );
};

export default LandingPage;
