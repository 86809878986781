import React from "react";
import { urlFor } from "../utils/urlFor";
import ModelCard from "./ModelCard";

export const BoatList = ({ data }) => {
  const { models } = data;

  return (
    <section className="py-6 bg-gray-50 sm:py-10 md:py-14">
      <div className="max-w-screen-xl p-4 m-auto">
        <div className="flex items-center justify-between">
          <h2 className="mb-10 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl">
            Modellene
          </h2>
        </div>
        <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3">
          {models?.map((model) => (
            <ModelCard
              key={model.title}
              title={model.title}
              introduction={model.introText}
              imageSrc={
                model?.prioritizedImage?.asset
                  ? urlFor(model.prioritizedImage).width(1000).height(600).url()
                  : ""
              }
              link={model.slug.current}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
