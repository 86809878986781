import React from "react";
import { BeakerIcon } from "@heroicons/react/24/solid";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

const Contact = () => {
  return (
    <div className="p-8 bg-white">
      <h2 className="mb-4 text-2xl font-bold">Kontakt oss</h2>

      <div className="flex items-center mb-3">
        <PhoneIcon className="w-6 h-6 mr-2 text-blue-600" />
        <a href="tel:+4797033832" className="text-gray-700 hover:text-blue-600">
          +47 970 33 832
        </a>
      </div>

      <div className="flex items-center">
        <EnvelopeIcon className="w-6 h-6 mr-2 text-blue-600" />
        <a
          href="mailto:post@stormermarine.no"
          className="text-gray-700 hover:text-blue-600"
        >
          post@stormermarine.no
        </a>
      </div>
    </div>
  );
};

export default Contact;
