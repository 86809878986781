import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import HomePage from "./HomePage";
import VeslingPage from "./VeslingPage";
import MuslingPage from "./MuslingPage";
import SportlingPage from "./SportlingPage";
import AboutPage from "./AboutPage";
import NotFound from "./NotFoundPage";
import NewsPage from "./NewsPage";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <Router>
      <Layout>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/vesling/*" element={<VeslingPage />} />
          <Route path="/musling/*" element={<MuslingPage />} />
          <Route path="/sportling/*" element={<SportlingPage />} />
          <Route path="/selskapet" element={<AboutPage />} />
          <Route path="/nyheter/*" element={<NewsPage />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
